/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box, Container} from "@chakra-ui/react";
import {CodeSandbox, YouTube, Gist} from "mdx-embed";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    p: "p",
    a: "a",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Context API"), "\n", React.createElement(_components.h3, null, "Pattern Two"), "\n", React.createElement(_components.p, null, "Pattern pulled from ", React.createElement(_components.a, {
    href: "https://react-tracked.js.org/docs/quick-start/#install-react-tracked"
  }, "recat tracked")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "import React, { ReactNode, createContext, useState, useContext } from 'react';\n\nconst initialState = {\n  count: 0,\n  text: 'hello',\n};\n\nconst useMyState = () => useState(initialState);\n\nconst MyContext = createContext<ReturnType<typeof useMyState> | null>(null);\n\nexport const useSharedState = () => {\n  const value = useContext(MyContext);\n  if (value === null) throw new Error('Please add SharedStateProvider');\n  return value;\n};\n\nexport const SharedStateProvider = ({ children }: { children: ReactNode }) => (\n  <MyContext.Provider value={useMyState()}>{children}</MyContext.Provider>\n);\n\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "import React from 'react';\nimport logo from './logo.svg';\nimport './App.css';\n\nimport { SharedStateProvider } from './store';\nimport Counter from './Counter';\nimport TextBox from './TextBox';\n\nconst App = () => (\n  <SharedStateProvider>\n    <div className=\"App\">\n      <header className=\"App-header\">\n        <Counter />\n        <TextBox />\n        <img src={logo} className=\"App-logo\" alt=\"logo\" />\n      </header>\n    </div>\n  </SharedStateProvider>\n);\n\nexport default App;\n")), "\n", React.createElement(_components.h3, null, "Pattern One"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import React, {createContext, useContext, useState} from 'react'\n\n\nexport const FooContext = createContext();\nexport const FooProvider = ({children}) => {\n    const { foo, setFoo} = usState()\n    return (\n        <FooContext.Provider value={{foo, setFoo}}>\n            {children}\n        </FooContext.Provider>\n    )\n}\n\nexport const useFooValue = () => useContext(FooContext)\n\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import React from 'react'\nimport ReactDOM from 'react-dom'\nimport * as serviceWorker from './serviceWorker'\nimport {\n  FooProvider,\n} from './foo-context.js'\n\nConst App = () => { return (\n  <div>\n   <h1>Context Pattern 2020</h1>\n  </div>\n}\n\nReactDOM.render(\n  <React.StrictMode>\n  \t<FooProvider>\n    \t<App />\n    </FooProvider>\n  </React.StrictMode>,\n  document.getElementById('root')\n)\n")), "\n", React.createElement(_components.h2, null, "Jotai"), "\n", React.createElement(_components.h3, null, "AtomWithReducer"), "\n", React.createElement(Box, {
    as: CodeSandbox,
    mb: 6,
    codeSandboxId: "jotai-atomwithreducer-example-1-xz6yc8?file=/src/index.js"
  }), "\n", React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://react-tracked.js.org/docs/quick-start/#install-react-tracked"
  }, "React Tracked"), " is an extension of the context API handling rerenders in a more efficient way only triggering re-renders if the accessed property is changed."), "\n", React.createElement(Box, {
    mb: 6
  }, React.createElement(YouTube, {
    youTubeId: "6yBv-_COJkk"
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://jotai.org/docs/core/atom"
  }, "Jotai"), " takes an atomic approach to global React state management with a model inspired by Recoil."), "\n", React.createElement(Box, {
    mb: 6
  }, React.createElement(YouTube, {
    youTubeId: "x9usS4l1VD0"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
